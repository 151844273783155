import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueMeta from 'vue-meta';
import 'odometer/themes/odometer-theme-car.css';
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import '@/assets/css/bootstrap-datetimepicker.min.css';
import '@/assets/js/moment.min.js';
import '@/assets/js/bootstrap-datetimepicker.min.js';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/DateTimePicker.min.css'
import '@/assets/js/DateTimePicker.min'
import {VueReCaptcha} from 'vue-recaptcha-v3'

Vue.use(VueReCaptcha, {
    siteKey: "6LdOo9YnAAAAAPAmHFQ8fSnWR9D4gWr60PyX3z5q",
    loaderOptions: {
        useEnterprise: true,
    }
});
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMeta)

Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
